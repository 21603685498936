<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small @click="getFacturas">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-btn
				class="ma-1"
				color="primary"
				small
				to="/autofacturacion/autofacturar"
				v-if="$root.acceso('CREAR_FACTURA_COLABORADORES')"
			>
				<v-icon left>mdi-plus</v-icon>Nueva liquidación
			</v-btn>
			<v-btn
				class="ma-1"
				color="primary"
				small
				@click.stop="facturaSinContrato"
				v-if="$root.acceso('CREAR_FACTURA_COLABORADORES')"
			>
				<v-icon left>mdi-plus</v-icon>Factura sin contratos
			</v-btn>
			<v-btn
				class="ma-1"
				color="primary"
				small
				@click="exportarAcumulativoAnual"
				:disabled="selected.length == 0 || !selected.every(f => f.estadoFactura == 'Pagada')"
			>
				<v-icon left>mdi-database-export-outline</v-icon>Exportar acumulativo anual
			</v-btn>
			<v-btn
				class="ma-1"
				color="primary"
				small
				to="/autofacturacion/comisiones"
				v-if="false"
			>
				<v-icon left>mdi-star-outline</v-icon>Ver comisiones especiales
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="facturas"
				:headers="facturasHeader"
				show-select
				class="fixed-checkbox"
				item-key="idFactura"
				v-model="selected"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="facturas" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.fechaFactura="{ item }">
					<span v-text="parseDate(item.fechaFactura)"></span>
				</template>

				<template v-slot:item.baseImponible="{ item }">
					<span v-text="`${Number(parseFloat(item.baseImponible).toFixed(2)) || 0} €`" />
				</template>
				<template v-slot:item.iva="{ item }">
					<span v-text="`${Number(parseFloat(item.iva).toFixed(2)) || 0} €`" />
				</template>
				<template v-slot:item.irpf="{ item }">
					<span v-text="`${Number(parseFloat(item.irpf).toFixed(2)) || 0} €`" />
				</template>
				<template v-slot:item.porcentajeIva="{ item }">
					<v-chip label v-text="item.porcentajeIva * 1 + '%'" />
				</template>
				<template v-slot:item.porcentajeIrpf="{ item }">
					<v-chip label v-text="item.porcentajeIrpf * 1 + '%'" />
				</template>
				<template v-slot:item.totalFactura="{ item }">
					<span
						class="font-weight-bold"
						v-text="`${Number(parseFloat(item.totalFactura).toFixed(2)) || 0} €`"
					/>
				</template>

				<template v-slot:item.estadoFactura="{ item }">
					<status-chip :value="item.estadoFactura" />
				</template>

				<template v-slot:item.numeroFactura="{ item }">
					<v-tooltip bottom v-if="item.archivo">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								color="secondary"
								text
								:loading="pdfId === item.idFactura"
								:disabled="pdfId"
								@click="verPDF(item.archivo)"
							>
								{{ item.numeroFactura }}
								<v-icon right>mdi-file-document-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>Ver PDF factura</span>
					</v-tooltip>
					<span
						v-else-if="item.numeroFactura"
						link
						color="secondary"
						text
						class="text-button secondary--text"
					>{{ item.numeroFactura }}</span>
				</template>

				<template v-slot:item.CodigoContrato="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/contrato/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
								color="secondary"
								text
							>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
						</template>
						<span>Detalles del contrato</span>
					</v-tooltip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" @click="detailsId = item.idFactura" icon color="secondary">
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</template>
						Ver detalles
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>

		<v-subheader>Saldos</v-subheader>
		<v-divider></v-divider>
		<v-row class="mt-4">
			<v-col v-for="{canal, saldo, nFacturas} in saldosPendientesCanal" :key="canal" cols="12" sm="4" md="3">
				<v-card class="d-flex flex-column" >
					<v-card-title class="flex-grow-0"> {{canal}}</v-card-title>
					<v-divider></v-divider>
					<v-card-text class="flex-grow-1">
						<h2 :class="{ 'error--text' : saldo < 0 }" >{{saldo}}€</h2>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-text class="flex-grow-0">
						<h4>Correspondiente a {{ nFacturas }} factura{{ nFacturas == 1 ? '' : 's' }}</h4>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="4" md="3">
				<v-card outlined >
					<v-card-title>Total</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<h2 :class="{ 'error--text' : totalSaldos < 0 }" >{{ totalSaldos }}€</h2>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
			:value="detailsId"
			@input="(v) => v === false ? detailsId = null : null"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card class="rounded-0">
				<v-toolbar dense color="primary" class="white--text">
					<v-toolbar-title>Detalles Factura</v-toolbar-title>
					<v-spacer />
					<v-btn color="white" icon @click="detailsId = null">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<detalles-facturacion
						@reload="getFacturas"
						@close="detailsId = null; getFacturas()"
						:idFactura="detailsId"
						v-if="detailsId"
						:key="detailsId"
					/>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="nuevaFactura.show" max-width="800">
			<v-card class="rounded-0 pt-3" :loading="colaboradores.length == 0">
				<!-- <v-toolbar dense color="primary" class="white--text">
					<v-toolbar-title>Nueva Factura</v-toolbar-title>
					<v-spacer />
					<v-btn color="white" icon @click.stop="nuevaFactura.reject">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>-->
				<v-card-text>
					<v-subheader>Elige al colaborador</v-subheader>
					<v-divider></v-divider>
					<v-list>
						<v-list-item
							v-for=" col in colaboradores "
							:key="col.idColaborador"
							@click.stop="nuevaFactura.resolve(col.esSubAgente ? col.name : col.NombreContrato)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ col.esSubAgente ? col.name : col.NombreContrato }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn
									color="primary"
									:loading="nuevaFactura.creating == (col.esSubAgente ? col.name : col.NombreContrato)"
									icon
								>
									<v-icon>mdi-cash-fast</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { getSituacionIcon, perColumnFilter, parseDate, downloadFile } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		DetallesFacturacion: () => import("./DetallesFactura.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	props: ['codigoContrato'],
	data() {
		return {
			debounce: true,
			loading: true,
			page: 2,
			selected: [],
			search: null,
			detailsId: this.$route.query.idFactura || null,
			pdfId: false,
			facturas: [],
			saldosCanal: [],
			saldosPendientesCanal: [],
			facturasHeader: [
				{ text: "Estado", dataType: "select", value: "estadoFactura" },
				{ text: "Acciones", value: "acciones", sortable: false, filterable: false, },
				{ text: "NºDoc", value: "numeroFactura" },
				{ text: "Fecha", dataType: "date", value: "fechaFactura" },
				{ text: "Canal", dataType: "select", value: "canal" },
				{ text: "Importe base", value: "baseImponible" },
				{ text: "% IVA", value: "porcentajeIva" },
				{ text: "€ IVA", value: "iva" },
				{ text: "% IRPF", value: "porcentajeIrpf" },
				{ text: "€ IRPF", value: "irpf" },
				{ text: "Total", value: "totalFactura" },
				{ text: "Numero líneas", value: "nLineas" },
				{ text: 'Tramitado por', value: 'Tramitador' }
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			inlineFilters: {},
			comboboxes: {},
			loadingPDF: null,

			nuevaFactura: {
				show: false,
				resolve: null,
				reject: null,
				creating: null
			},
			colaboradores: [],
		};
	},
	computed: {
		totalSaldos() {
			 return parseFloat( this.saldosPendientesCanal.reduce( (t, c) => t + c.saldo , 0)).toFixed(2) * 1
		}
	},
	methods: {
		getSituacionIcon,
		parseDate,
		async getFacturas() {
			this.loading = true;
			const { data } = await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/autoFacturas.php`,
				method: 'GET',
				params: { token: this.$store.getters.getJwtEmpresa }
			});

			this.facturas = data.facturas;
			this.saldosCanal = data.saldosCanal;
			this.saldosPendientesCanal = data.saldosPendientesCanal;

			this.loading = false;
		},
		async getColaboradores() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
					params: {
						token: this.$store.getters.getJwtEmpresa,
					},
				}).then((res) => {
					this.colaboradores = res.data.map((c) => {
						c.name = c.Nombre;
						c.Nombre = [...new Set([c.NombreContrato, c.Nombre])].join("__");
						c.esCanal = c.ancestor_id == null;
						c.esSubAgente = c.ancestor_id != null;
						c.facturable = [c.IBAN, c.porcentajeIva, c.porcentajeIrpf, c.direccion, c.cp, c.ciudad, c.provincia, c.cif].every(con => con != null);
						return c;
					}).filter(c => {
						// console.log({ fac: c.facturable, sub: c.esSubAgente, nombre: c.NombreContrato })
						return c.facturable
					}).sort((a, b) => (a.name) < b.name ? -1 : 1);
					resolve();
				});
			})
		},
		verPDF(archivo) {
			axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					archivo: archivo,
				},
				responseType: 'blob',
			}).then(res => {
				downloadFile(new File([res.data], archivo, { type: 'application/pdf' }), true);
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'Ha ocurrido un error');
			})
		},
		exportarAcumulativoAnual() {
			let canal = [];
			if (this.selected.length > 0) {
				canal = [...new Set(this.selected.map(s => s.canal))]
			} else {
				canal = [...new Set(this.facturas.map(s => s.canal))]
			}
			axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/acumulativoAnual.php`,
				responseType: 'blob',
				params: {
					canal,
					token: this.$store.getters.getJwtEmpresa,
				}
			}).then(res => {
				const MESES = [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				];
				let excel = new File(
					[res.data],
					`Acumulativo_comisiones_completo_${MESES[new Date().getMonth()]}_${new Date().getFullYear()}.xlsx`,
					{
						type: "application/excel",
					}
				);
				downloadFile(excel, false);
			})
		},
		async facturaSinContrato() {
			const auxPromise = () => {
				return new Promise(async (resolve, reject) => {
					this.nuevaFactura.show = true;
					this.nuevaFactura.resolve = resolve;
					this.nuevaFactura.reject = reject;
				})
			}

			try {
				const canal = await auxPromise();
				this.nuevaFactura.creating = canal;

				axios({
					method: 'POST',
					url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
					data: {
						canal: [canal],
						fecha: new Date().getTime(),
						token: this.$store.getters.getJwtEmpresa,
						sinLineas: true,
					}
				}).then(res => {
					this.$root.$emit('snack', 'Factura generada correctamente')
					this.nuevaFactura.creating = null;
					this.nuevaFactura.show = false;
					this.nuevaFactura.resolve = null;
					this.nuevaFactura.reject = null;

					this.getFacturas();
				})

			} catch (e) {
				console.error(e);
				this.nuevaFactura.show = false;
				this.nuevaFactura.resolve = null;
				this.nuevaFactura.reject = null;
			}
		}
	},
	mounted() {
		this.getFacturas();
		this.getColaboradores();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>
